@import "../../../assets/styles/variables";

.White {
  text-decoration: none;
  color: $link_white !important;
  font-size: 16px;
  cursor: pointer;
  margin-right: 40px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $primary-p1;
    color: $link_white;
  }

  &:active {
    text-decoration: underline;
    text-decoration-color: $primary-p1;
    color: $link_white;
  }
}

.Primary {
  text-decoration: none;
  color: $link_primary;
  cursor: pointer;
  margin-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $primary-p1;
    color: $link_primary;
  }

  &:active {
    text-decoration: underline;
    text-decoration-color: $primary-p1;
    color: $link_primary;
  }
}

/* custom css jigar */
.ctblacktxt a {
  text-decoration: none;
  color: #fff !important;
  font-size: 18px;
  cursor: pointer;
  margin-right: 40px;
  padding: 10px;

  &:hover {
    text-decoration: none;
    text-decoration-color: $primary-p1;
    color: #fff !important;
    //     box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
    // -webkit-box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
    // -moz-box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.active {
  // box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
  // -webkit-box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
  // -moz-box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.earlyaccessbtn {
  background: -moz-linear-gradient(0% 50% 0deg,
      rgba(67, 127, 236, 1) 17.18%,
      rgba(51, 108, 213, 1) 46.8%,
      rgba(24, 78, 174, 1) 100%);
  background: -webkit-linear-gradient(0deg,
      rgba(67, 127, 236, 1) 17.18%,
      rgba(51, 108, 213, 1) 46.8%,
      rgba(24, 78, 174, 1) 100%);
  background: -webkit-gradient(linear,
      0% 50%,
      100% 50%,
      color-stop(0.1718, rgba(67, 127, 236, 1)),
      color-stop(0.468, rgba(51, 108, 213, 1)),
      color-stop(1, rgba(24, 78, 174, 1)));
  background: -o-linear-gradient(0deg,
      rgba(67, 127, 236, 1) 17.18%,
      rgba(51, 108, 213, 1) 46.8%,
      rgba(24, 78, 174, 1) 100%);
  background: -ms-linear-gradient(0deg,
      rgba(67, 127, 236, 1) 17.18%,
      rgba(51, 108, 213, 1) 46.8%,
      rgba(24, 78, 174, 1) 100%);
  background: linear-gradient(90deg,
      rgba(67, 127, 236, 1) 17.18%,
      rgba(51, 108, 213, 1) 46.8%,
      rgba(24, 78, 174, 1) 100%);
  color: white;
  padding: 10px 18px;
  border-radius: 4px;
}



@media screen and (max-width: 992px) {
  .White {
    margin-right: 0px;
    padding: 12px !important;
  }
}