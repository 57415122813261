body {
    -webkit-overflow-scrolling: touch
}

.copymaindiv {
    page-break-before: always;
    page-break-after: always;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.marginleft30 {
    margin-left: 30px;
}



@media only screen and (max-width: 600px) {
    .infoModal {
        height: 400px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch
    }

    .copymaindiv {
        font-size: 12px;
    }

    .modal-width {
        min-width: auto;
    }
}

.copyContainer {
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
}

.copyButtonContainer {
    border-radius: 5px;
    background: #0000d2;
    color: white;
    width: 135px;

}
