.marginTop{
    margin-top: 150px;
}

.display-4{
    font-size: 4.3rem !important;
    font-weight: 700 !important;
}
.subHeader {
    font-size: 30px;
    padding-bottom: 10px;
}

@media (max-width: 990px) {
    .marginTop{
        margin-top: 0px !important;
    }
  }

  @media (min-width: 1441px) {
    .large-fontsize{
        font-size: 1.5rem;
    }
  }