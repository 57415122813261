.input-group {
    /*background-color: #ffffff;*/
    padding: 16px !important;
   /* width: 75% !important;*/
    border-radius: 2px;

    form {
        width: 100%;

        .form-group {
            label {
                color: black;
            }
        }
    }
}
.subHeader {
    font-size: 30px;
    padding-bottom: 10px;
}
.display-4 {
    font-size: 4.3rem !important;
    font-weight: 700 !important;
}

.btn-primary {
    background-color: #007bda !important;
    border-color: #007bda !important;
    &:disabled{
        opacity: 0.5 !important;
        background-color: #000 !important;   
    }
}

.arial-font {
    font-family: arial;
}

.formbtnget {
    background: -moz-linear-gradient(0% 50% 0deg,rgba(67, 127, 236, 1) 17.18%,rgba(51, 108, 213, 1) 46.8%,rgba(24, 78, 174, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(67, 127, 236, 1) 17.18%, rgba(51, 108, 213, 1) 46.8%, rgba(24, 78, 174, 1) 100%);
    background: -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0.1718,rgba(67, 127, 236, 1) ),color-stop(0.468,rgba(51, 108, 213, 1) ),color-stop(1,rgba(24, 78, 174, 1) ));
    background: -o-linear-gradient(0deg, rgba(67, 127, 236, 1) 17.18%, rgba(51, 108, 213, 1) 46.8%, rgba(24, 78, 174, 1) 100%);
    background: -ms-linear-gradient(0deg, rgba(67, 127, 236, 1) 17.18%, rgba(51, 108, 213, 1) 46.8%, rgba(24, 78, 174, 1) 100%);
    background: linear-gradient(90deg, rgba(67, 127, 236, 1) 17.18%, rgba(51, 108, 213, 1) 46.8%, rgba(24, 78, 174, 1) 100%);
}

.inp {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    background-color: white !important;
    height: 48px !important;
    border-color: white !important; 
}
.fontsstle {
    font-size: 36px;
}
.contentImg{
    text-align: center;
    position: relative !important;
    img{
        // max-height: 400px;
        width: 100%;
        // -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        // -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        // box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    }
    .infoText{
        position: absolute;
        background-color: rgba(255,255,255,0.9);
        padding: 7px 10px;
        font-size: 12px;
        color: #000;
        top: 110px;
        left: 60px;
        width: 200px;
        line-height: 1.2;
    }
}
.bg-light-blue{
    background-color: #C2D4FB;
}
.bg-light-blue2{
    background-color: #ccdcfc;
}
.homeBannerHead{
    font-family: 'Source Serif Pro';
    padding-top: 80px;
    font-size: 76px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff;

}
.whiteBox{
    border-radius: 20px;
    padding: 15px 20px;
    width: 100%; 
    height: 100%;
    -webkit-box-shadow: 2px 5px 20px 12px rgba(0,0,0,0.05);
    -moz-box-shadow: 2px 5px 20px 12px rgba(0,0,0,0.05);
    box-shadow: 2px 5px 20px 12px rgba(0,0,0,0.05);
}
.text-blue{
    color: #003392;
}

.videoPlayer {
    border-radius: 15px;
}
@media screen and (min-width: 1441px) {
    .large-fontsize {
        font-size: 1.5rem;
    }
}
@media screen and (max-width:1200px) {
    .screenshotImg{
        .infoText{
            top: 92px;
            font-size: 11px;
        }
    }
    .homecontmpad {
        // padding-top: 100px;
   
        background: url(../../assets/images/home-banner-bg.png) no-repeat 0px 0px;
    }
}
@media screen and (max-width:992) {
    .screenshotImg{
        .infoText{
            top: 65px;
            left: 40px;
            width: 160px;
            font-size: 10px;
        }
       
    }
}



.dZkckO {
    right: -20px !important;
}

.bBfHpH {
   left: -20px !important;
}

@media screen and (max-width: 767px) {
   .cSNndH {
       display: inline-block !important;
   }

   .gZzsDe {
       display: inline-block !important;
   }   
   
   .dZkckO {
       right: -8px !important;
  }

  .bBfHpH {
       left: -8px !important;
   }
}

@media screen and (max-width:992) {
   .cSNndH {
       display: inline-block !important;
   }
   
   .gZzsDe {
       display: inline-block !important;
   }    
}

@media screen and (max-width: 1200px) {
   .cSNndH {
       display: inline-block !important;
   }
   
   .gZzsDe {
       display: inline-block !important;
   }    
}

@media screen and (max-width: 1441px) {
   .cSNndH {
       display: inline-block !important;
   }
   
   .gZzsDe {
       display: inline-block !important;
   }    
}


// =========NEW HOME===========
.Nav-Container{
    background-color: #ffffff;
}
.navbar-nav {
    // padding-right: 50px;
    a{font-family: proxima-nova, sans-serif;}
    .White{
        color: #000000 !important;
    }
}
// .loginSignup a {
//     border: 0;
//     background: #ffffff;
//     -webkit-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
//     -moz-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
//     box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
//     color: #0A0E13;
//     border-color: #000000 !important;
//     padding: 5px 12px !important;
//     border-radius: 20px !important;
//     color: #003392 !important;
//     font-weight: 500 !important;
//     font-size: 14px !important;
//     font-family: proxima-nova, sans-serif;
//     margin-right: 0px;
//     &:hover{
//         background: #003392;
//         color: #ffffff;
//     }
// }

.btnBlue {
    border: 0 !important;
    background: #003392;
    color: #ffffff;
    border-color: #003392 !important;
    padding: 5px 12px !important;
    border-radius: 20px !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    font-family: proxima-nova, sans-serif;
    margin-right: 0px;
    &:hover{
        background: #ffffff;
        color: #003392;
        -webkit-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
        -moz-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
        box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
    }
}
.navbar-light .navbar-toggler {
    background: #ccc;
}
.navbar-expand-lg .navbar-nav .nav-link{
    padding: 6px !important;
}

.copyText{
    font-family: proxima-nova, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1.2;
}
.font-16{
    font-size: 16px;
}
.font-20{
    font-size: 20px;
}
.bg-light-blue3{
    background-color: #D2D9FF;
}
.bg-light-blue4{
    background-color: #EEF0FF; 
}
.bg-light-blue5{
    background-color: #FBF9FE; 
}
.bg-blue1{
    background-color: #3C4CCB;
}
.bg-blue2{
    background-color: #003392;
}
.bg-blue-grad{
    background: url(../../assets/images/bg-blue-grad.svg) center;
    background-size: cover;
}
.bg-yellow-grad{
    background: url(../../assets/images/bg-yellow-grad.svg) center ;
    background-size: cover;
}
.bg-green{
    background-color: #398554;
}
.bg-gray1{ 
    background-color: #F1F0EE;
}
.bannerTitle{
    font-family: quincy-cf, serif;
    font-weight: 700;
    font-style: normal;
    color: #000000;
    line-height: 1;
}
.bannerTitle2{
    // color: #17150D;
    font-family: quincy-cf, serif !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px; /* 107.5% */
    letter-spacing: -0.8px;
}
.hrBlueLight{
    border-top: 1px solid rgba(0, 51, 146, 0.2);
    margin-top: 2.4em;
}
.bannerTitleWhite{
    // padding: 12px 0;
    // width: 500px;
    // background: #ffffff;
    z-index: 1;
    position: relative;
    // border-radius: 0 8px 8px 0;
}
.bannerText{
    font-family: quincy-cf, serif;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    color: #000000;
    letter-spacing: 0.5px;
   }
.stroke-img{
    position: absolute;
    top: 33px;
    z-index: -1;
}
.sectionOne{
   padding-top: 80px;
   .inp {
    box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.28);
   }
}
.sectionOneWhite{
//    background: #ffffff;
//    border-radius: 40px 0 0 40px;
   padding: 90px 0 30px 0px;
   .bannerUlText{
        // list-style-image: url("../../assets/images/star-bullet.svg");
        padding-inline-start:0;
        li{
            background: url('../../assets/images/star-bullet.svg') no-repeat left 4px;
            padding: 5px 0px 5px 35px;
            list-style: none;
            margin: 0;
            // &::before {
            //     content: '';
            //     display: inline-block;
            //     height: 24px;
            //     width: 24px;
            //     background-image: url(../../assets/images/star-bullet.svg);
            //   }
        }
        
   }
}
.heroImg{
    border-radius: 0 40px 40px 0;
}

.banner2Text{
    
    text-align: center;
    h5{
        font-family: quincy-cf, serif;
        font-size: 23px;
        margin: 0;
        padding-top: 20px;
        
    }
}
.blackArrowCircle, .blackEqualCircle{
    padding-top: 100px;
}
.ellipseSection{
    padding-left: 80px;
}

.tagTitle{
    color: #ffffff;
    border-radius: 48px;
    padding: 7px 15px;
    font-family: proxima-nova, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: initial;
}

.blueTag{
    display: inline-block;
    // padding: 4px 20px;
    font-family: quincy-cf, serif;
    font-style: italic;
    border-radius: 8px;
    // background: #E0FBFF;
   color:#2D4773;
}
.blueTag2{
    
    background: #2D4773;
   
}

.studentBox2{
    text-align: left !important;
    background-color: #ffffff;
    border: 1px solid #2E2D1E;
    height: auto;
    padding: 32px;
    border-radius: 40px;
    margin: 5px;
    p{margin: 0;}
}
.shadow1{
    -webkit-box-shadow: 0px 1px 8px 3px rgba(163, 192, 242, 0.50);
    -moz-box-shadow: 0px 1px 8px 3px rgba(163, 192, 242, 0.50);
    box-shadow: 0px 1px 8px 3px rgba(163, 192, 242, 0.50);
}
.whiteBgText{
    margin: 0;
    padding: 8px 20px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    display: inline-block;
    font-family: quincy-cf, serif;
    font-size: 18px;
    font-style: italic;
    letter-spacing: 1px;
}
.fbcontainer {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    max-height: 1000px;
    /* Your container needs a fixed height, and it 
     * needs to be taller than your tallest column. */
    // height: 960px;
    
    /* Optional */
    // background-color: #f7f7f7;
    // border-radius: 3px;
    // padding: 20px;
    // width: 60%;
    // margin: 40px auto;
    counter-reset: items;
  }
  
  .item {
    width: 32%;
    height: 360px;
    /* Optional */
    position: relative;
    margin-bottom: 2%;
    border-radius: 32px;
    background-color: #000000;
    // border: 1px solid #4290e2;
    // box-shadow: 0 2px 2px rgba(0,90,250,0.05),
    //   0 4px 4px rgba(0,90,250,0.05),
    //   0 8px 8px rgba(0,90,250,0.05),
    //   0 16px 16px rgba(0,90,250,0.05);
    // color: #fff;
    padding: 24px;
    box-sizing: border-box;
    .boxText{
        margin: 0;
        padding-top: 10px;
        text-align: center;
        font-family: proxima-nova, sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 31px; /* 129.167% */
        text-transform: capitalize;
    }
  }
  
   /* Just to print out numbers */
  div.item::before {
    counter-increment: items;
    // content: counter(items);
  }
  
  /* Re-order items into 3 rows */
//   .item:nth-of-type(4n+1) { order: 1; }
//   .item:nth-of-type(4n+2) { order: 2; }
//   .item:nth-of-type(4n+3) { order: 3; }
//   .item:nth-of-type(4n)   { order: 4; }

.item:nth-of-type(3n+1) { order: 1; }
.item:nth-of-type(3n+2) { order: 2; }
.item:nth-of-type(3n)   { order: 3; }
  
/* Force new columns */
.break {
    flex-basis: 100%;
    width: 0;
    border: 1px solid #ddd;
    margin: 0;
    content: "";
    padding: 0;
}

.whiteBdrDiv{
    height: 100%;
    border-radius: 8px;
    border: 1px solid #3C4CCB;
    padding: 20px;
    text-align: center;
}
.yellowBdrDiv{
    height: 100%;
    border-radius: 8px;
    border: 1px solid #FFB802;
    padding: 20px;
    text-align: center;
}
.yellowBox{
    background-color: #FFF693;
}
.whiteBoxContainer{
    background-color: #FFFFFF;
    border-radius: 32px;
    padding: 20px;
    height: 330px;
    margin-bottom: 10px;
}
.boxImg1{
    background-image: url(../../assets/images/boxImg1.png);
    background-size: cover;
}
.boxImg2{
    background-image: url(../../assets/images/boxImg2.png);
    background-size: cover;
}
.boxImg3{
    background-image: url(../../assets/images/boxImg3.png);
    background-size: cover;
}
.waveLineImg{
    background: url(../../assets/images/waveLines.svg) no-repeat bottom right;
}

.gradientBannerBg{
    background: url(../../assets/images/bg-banner-gradient.png) no-repeat center center;
    background-size: cover;
    height: 180px;
    border-radius: 40px;
}
.banner-pt{
    padding-top: 75px;
}
.bg-grid-light{
    background: url(../../assets/images/bg-grid-gradient.svg) no-repeat 0px 0px;
    background-size: cover;
}
.grid-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    .grid-section-text{
        width: 300px;
    }
    p{
        &:nth-child(odd){
            margin-right: 120px;
        }
        &:nth-child(even){
            margin-left: 220px;
        }
    }
}

.infoModal {
    // width: 100%;
    // height: 100%;
    max-width: 70%;
    background-color: white;
    padding: 20px;
    border: 1px solid;
    border-radius: 20px;
    text-align: center;

    .headerItemsContainer {
        position: relative;
    
        .closeButton {
            position: absolute;
            right: 0;
            top: -10px;
        }
    }
}

.videoClass {
    width: 100%;
    position: fixed;
    display: flex;
    left: 85%;
    top: 85%;
    z-index: 10;
  }
  
  .videoModalDiv {
    text-align: center;
    align-items: center;
    background-color: #fff;
    width: 600px;
  }
  
  .videoHeader {
    margin-top: 15px;
  
    span {
        color: #4353B0 !important;
        font-size: 26px;
        line-height: 1.5rem;
    }
  }
  
  .videoDescription p {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.5rem;
  }
  
  
  .videoClosebtn {
    margin-top: 17px;
    background-color: white !important;
    color: #4353B0 !important;
    border-color: #4353B0 !important;
    font-weight: bold;
  }
  
  .videoDiv {
    height: 100%;
    width: 600px;
    margin-left: 20px;
  }
@media screen and (max-width: 1200px) {
    .bannerTitleWhite{
        width: 400px;
    }
    // .sectionOneWhite {
    //     padding: 30px 0 0px 40px !important;
    // }
    .sectionOne{
        
    }
    .blackArrowCircle, .blackEqualCircle{
        padding-top: 80px;
    }
    .videoDiv{
        width: 350px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 992px) {
   
    .sectionOneWhite {
        padding: 20px 0 30px 0px;
    }
    .heroImg {
        border-radius: 0 0 40px 40px;
    }
    .bannerTitleWhite {
        width: auto;
    }
    .blackArrowCircle, .blackEqualCircle{
        padding-top: 60px;
    }
    .ellipseSection {
        padding-left: 50px;
    }
    .fbcontainer{ max-height: 1300px;}
    .item { width: 46%;}
    .item:nth-of-type(2n+1) { order: 1; }
    .item:nth-of-type(2n) { order: 2; }

    .bg-blue-grad{
        background: url(../../assets/images/bg-blue-grad.svg) left;
        background-size: auto;
    }
    .bg-yellow-grad{
        background: url(../../assets/images/bg-yellow-grad.svg) right ;
        background-size: auto;
    }
    .videoDiv{
        width: 350px;
        margin-left: 0px;
    }
   
}
@media screen and (max-width: 768px) {
   
    .grid-section{
        
        .grid-section-text{
            width: auto;
        }
        p{
            &:nth-child(odd){
                margin-right: 0px;
            }
            &:nth-child(even){
                margin-left: 0px;
            }
        }
    }
    .videoDiv{
        width: 350px;
        margin-left: 0px;
    }
    
}
@media screen and (max-width: 767px) {
    .blackArrowCircle, .blackEqualCircle{
        padding-top: 10px;
        padding-bottom: 30px;
    }
    .blackArrowCircle{
        transform: rotate(90deg);
        padding-bottom: 10px;
        margin-bottom: 15px;
    }
    .ellipseSection {
        padding-left: unset;
    }
    .fbcontainer{ max-height: 2500px;}
    .item { width: 96%;}
    .item:nth-of-type(1n+1) { order: 1; }
    .videoDiv{
        width: 350px;
        margin-left: 0px;
    }
    
}
@media only screen and (max-width: 600px) {
    .videoDiv{
        width: 350px;
        margin-left: 0px;
    }
}