.rowHome {
    display: flex;
    flex-wrap: wrap;
}

.inlineHeigth {
    line-height: normal;
}

ul {
    padding-inline-start: 16px;
}

.subHeader {
    font-size: 30px;
    padding-bottom: 10px;
}

.quattrocento-font {
    font-family: 'Quattrocento Sans', sans-serif;
}

.display-1 {
    font-size: 7rem;
    font-weight: 700 !important;
}

.mid-screen {
    margin-top: -65px;
}

.teacherContentImg {
    // text-align: center;
    position: relative !important;
    align-items: center;

    img {
        // max-height: 400px;
        width: 100%;
        // -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        // -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        // box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    }

}

.tutorBanner {
    font-family: 'quincy-cf', serif;
    padding-top: 40px;
    font-size: 48px;
    font-weight: 700;
    line-height: 0.5;
    letter-spacing: 0.01em;
    text-align: left;
    color: #000000;
    text-align: center;

    h1 {
        text-align: center;
        // background: #ffffff;
        display: initial;
        padding: 5px 40px;
        border-radius: 5px;
    }
}

.bulletsContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    // ul{
    //     list-style-position: inside;
    // }
}

ul {
    font-family: proxima-nova, serif;
    font-size: 20px;
}

.calendlyButton {
    padding: 0;
    cursor: pointer;
    border: 0;
    background: #ccdcfc;
    text-decoration: underline;
    font-style: italic;
    color: blue;
}

.topicText {
    font-size: 20px;

    li {
        font-family: proxima-nova, serif;
    }

    // padding-left: 5px;
}

@media (min-width: 1441px) {
    .large-fontsize {
        font-size: 1.5rem;
    }

}

@media (max-width: 990px) {
    .mid-screen {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .gradientBannerBg {
        //  height: 180px;
        height: 100%;
        padding-bottom: 20px;
    }
}

.tippy-content {
    font-size: 16px !important;
}