.footerContainer {
    width: 100%;
    height: 100%;
    color: #ffffff;

}

.companyLink {
    display: flex;
    flex-direction: column;

    a {
        color: #ffffff;
        font-size: 14px;
        line-height: 30px;

        &:hover {
            color: #ffffff;
        }
    }
}

.copyrightText {
    img {
        width: 130px;
        height: auto;
    }

    p {
        font-size: 14px;
        margin: 10px 0 0px 0px;
    }
}