.Nav-Container {
    background-color: rgba(1, 2, 28, 0.4);
    backdrop-filter: blur(10px);
    position: absolute;
    z-index: 1000;
}

.Navbar {
    padding-top: 30px;
}

.nav-link {
    text-wrap: nowrap;
}

.ContainerWidth {
    width: 90% !important;
}

.navbar-brand img {
    height: auto;
    max-width: 130px;
}

.navindexn {
    z-index: 1;
}

.SectionContainer {
    justify-content: flex-end !important;
}

button {
    &.collapsed {
        .navbar-toggler-icon {
            background-image: url(../../assets/images/menu-icon.svg);
        }
    }
}

button:not(.collapsed) {
    .navbar-toggler-icon {
        background-image: url(../../assets/images/menu-close-icon.svg);
    }
}

.loginSignup {
    display: inline-flex;
    gap: 10px;

    a {
        border: 0;
        padding: 10px 20px;
        border-radius: 20px;
        font-weight: 500;
        font-size: 14px;
        margin-right: 10px;
    }

    a {

        //LOGIN BTN
        &:first-child {
            background: #003392;
            color: #ffffff;



            &:hover {
                background: #ffffff;
                color: #003392 !important;
                -webkit-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
                -moz-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
                box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
            }
        }

    }

    a {

        //SIGN BTN
        &:last-child {
            background: #ffffff;
            color: #003392 !important;
            -webkit-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
            -moz-box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);
            box-shadow: 0px 1px 6px 2px rgba(163, 192, 242, 0.2);



            &:hover {
                background: #ffffff;
                color: #003392 !important;
                -webkit-box-shadow: 0px 0px 0px 1px rgba(163, 192, 242, 1);
                -moz-box-shadow: 0px 0px 0px 1px rgba(163, 192, 242, 1);
                box-shadow: 0px 0px 0px 1px rgba(163, 192, 242, 1);
            }
        }

    }



}


@media (min-width: 1441px) {
    // .navbar-brand img{
    //     height: 70px;
    // }
}

@media screen and (max-width: 992px) {
    .navbar {
        flex-direction: column;
        align-items: end !important;
    }

    .navbar-nav {
        padding-right: 0px !important;
    }
}

.navbar-nav a:hover,
.navbar-nav .nav-link.active {
    color: #003392 !important;
}