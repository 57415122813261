.student-input-group {
  /*background-color: #ffffff;*/
  padding: 16px;
  /* width: 75% !important;*/
  border-radius: 2px;

  form {
    width: 100%;

    .form-group {
      label {
        color: black;
      }
    }
  }
}

.display-4 {
  font-size: 4.3rem !important;
  font-weight: 700 !important;
}

.btn-primary {
  background-color: #007bda !important;
  border-color: #007bda !important;
}

.arial-font {
  font-family: arial;
}

.formbtnget {
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(67, 127, 236, 1) 17.18%,
    rgba(51, 108, 213, 1) 46.8%,
    rgba(24, 78, 174, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(67, 127, 236, 1) 17.18%,
    rgba(51, 108, 213, 1) 46.8%,
    rgba(24, 78, 174, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0.1718, rgba(67, 127, 236, 1)),
    color-stop(0.468, rgba(51, 108, 213, 1)),
    color-stop(1, rgba(24, 78, 174, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(67, 127, 236, 1) 17.18%,
    rgba(51, 108, 213, 1) 46.8%,
    rgba(24, 78, 174, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(67, 127, 236, 1) 17.18%,
    rgba(51, 108, 213, 1) 46.8%,
    rgba(24, 78, 174, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(67, 127, 236, 1) 17.18%,
    rgba(51, 108, 213, 1) 46.8%,
    rgba(24, 78, 174, 1) 100%
  );
}
.homecontmpad {
  padding-top: 100px;
  // padding-bottom: 100px;
}
.inp {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  background-color: white !important;
  height: 48px !important;
  border-color: white !important;
}
.fontsstle {
  font-size: 36px;
}
.studentContentImg {
  // text-align: center;
  position: relative !important;
  align-items: center;
  img {
    // max-height: 400px;
    width: 100%;
    // -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    // -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    // box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
  }
}
@media (min-width: 1441px) {
  .large-fontsize {
    font-size: 1.5rem;
  }
}
