.rowHome {
    display: flex;
    flex-wrap: wrap;
}

.inlineHeigth {
    line-height: normal;
}

ul {
    padding-inline-start: 16px;
}

.quattrocento-font {
    font-family: 'Quattrocento Sans', sans-serif;
}

.careerContainer {
    hr {
        // border-top: 1px solid rgba(0, 0, 0, 0.3);
        border-top: 1px solid #FDBD17;
        margin: 40px 0 40px 0;
    }

    .hrclass {
        border-top: 1px solid #FDBD17;
        margin: 0px 0 40px 0;
    }
}

.careerLinks {
    padding-top:25px;
    padding-bottom: 15px;
    // text-align: right;

    button {
        border: 0;
        background-color: #000000;
        padding: 5px 12px;
        cursor: pointer;
        margin-right: 15px;
        border-radius: 20px;
        color: #ffffff;
        font-family: proxima-nova, sans-serif;
        &:hover {
            text-decoration: none;
            background-color: #e4a301;
            color: #fff;
        }
    }
}

// .display-1 {
//     font-size: 7rem;
//     font-weight: 700 !important;
// }

.mid-screen {
    margin-top: -65px;
}

@media (min-width: 1441px) {
    .large-fontsize {
        font-size: 1.5rem;
    }

}

@media (max-width: 990px) {
    .mid-screen {
        margin-top: 0px;
    }
}
@media (max-width: 768px) {
    .careerLinks {
        button {
            padding: 5px 5px;
            margin-right: 5px;
            margin-bottom: 5px;
            font-size: 14px;
        }
    }
    .careerContainer{ hr {
            margin: 0px 0 10px 0;
        }
    }
}
@media (max-width: 480px) {
    .careerLinks {
        button {
            font-size: 12px;
        }
    }
}
