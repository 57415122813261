.marginTop{
    margin-top: 150px;
}

.link {
    color: #ffffff;
}

.display-4{
    font-size: 4.3rem !important;
    font-weight: 700 !important;
}
.MuiCardContent-root{
    padding: 10px !important;
}
.teamsCards{
    .MuiCardContent-root {
        padding: 10px !important;
        font-family: proxima-nova, sans-serif !important;
    }
    .MuiTypography-root{
        font-family: proxima-nova, sans-serif !important;
    }
}
@media (min-width: 1441px) {
    .large-fontsize{
        font-size: 1.5rem;
    }
    .marginTop{
        margin-top: 200px !important;
    }
}

@media (max-width: 990px) {
    .marginTop{
        margin-top: 0px !important;
    }
  }

@media (max-width: 768px) {
    .mnh1o {
        font-size: 40px;
       line-height: 1;
    }
    .MuiCardContent-root{
        padding: 10px 10px 5px 5px !important;
    }
    .MuiTypography-root{
        font-size: 0.75rem !important;
    }
  }


  