// video {
//     max-width: 100%;
//     border: 2px solid #333;
// }

// button {
//     padding: 10px 20px;
//     background-color: #0074d9;
//     color: #fff;
//     border: none;
//     cursor: pointer;
// }

// button:hover {
//     background-color: #0056b3;
// }

// canvas {
//     display: none;
// }

// img {
//     max-width: 100%;
//     margin-top: 20px;
// }

.cameraHolder{
    position: relative;
    video, img {
        max-width: 100%;
        margin: 0 auto;
    // border: 2px solid #333;
    }
}
.cameraBtns{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    position: absolute;
    bottom: 10px;
    button{
        // margin: 0 auto;
        background: transparent;
        border: 0;
    }
}